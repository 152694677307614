import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  AfterViewInit,
  HostBinding,
  ElementRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';

import { MenuInternalService } from './MenuControl.service';
import { zAppDevMenuItem } from './menu-item';

import * as ThemeOptions from '../../theme/interfaces/options';
import { IThemeSevice } from '../../theme/interfaces/theme.interface';
import { zAppDevComponentStatus } from '../component-status';
import { CF_COMPONENT } from '@framework/rule-engine/directives/condition-formatting.directive';
import { zAppDevBaseComponent } from '../BaseComponent/base.component';
import { transformStyleToObject } from '@framework/common';
import { ContextService } from '../../../@core/services/Context.service';
import { PermissionService } from '../../security/services/permission.service';

@Component({
  selector: 'zapp-menu',
  styleUrls: ['./MenuControl.component.less'],
  templateUrl: './MenuControl.component.html',
  providers: [{ provide: CF_COMPONENT, useExisting: zAppMenuControlComponent }]
})
export class zAppMenuControlComponent extends zAppDevBaseComponent {
  protected destroy$ = new Subject<void>();

  menus: MenuItem[];
  verticalClass: string = "";
  options: ThemeOptions.MenuThemeOptions;

  private _items: zAppDevMenuItem[];
  get items(): any {
    return this._items;
  }
  @Input() set items(value: any) {
    this._items = value;
    this.refreshMenu();
  }

  @Input() variation: string = 'Standard';

  @Input() status: zAppDevComponentStatus = 'default';

  @Input() class: string = '';

  @Input() type: string = "NavBar";

  @Input() notCollapsible: boolean = false;

  localeInitialized: boolean = false;

  primeNgBugToggle: boolean = true;

  constructor(
    protected readonly translate: TranslateService,
    protected menuInternalService: MenuInternalService,
    private themeService: IThemeSevice,
    private contextService: ContextService,
    private permissionService: PermissionService,
    protected elementRef: ElementRef) {
    super(elementRef);
    this.options = themeService.getMenuThemeOptions();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.verticalClass = this.type == "Side" ? "vertical" : "";

    this.translate
      .onLangChange
      .pipe()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.localeInitialized = true;
        this.refreshMenu();
      });

    this.contextService.context$
      .pipe(skip(1))
      .pipe(takeUntil(this.destroy$))
      .subscribe((context) => {

        if (!this.localeInitialized) {
          return;
        }

        this.refreshMenu();
      });

    this.permissionService.permissions
      .pipe(skip(1))
      .pipe(takeUntil(this.destroy$))
      .subscribe((context) => {

        if (!this.localeInitialized) {
          return;
        }

        this.refreshMenu();
      });

  }

  refreshMenu() {
    const prevKey = this.getItemsKeys(this.menus);
    this.menus = this.menuInternalService.prepareItems(this.items);
    if (prevKey == this.getItemsKeys(this.menus)) {
      return;
    }

    this.primeNgBugToggle = false;
    setTimeout(() => this.primeNgBugToggle = true, 0);
  }

  getItemsKeys(items: MenuItem[]) {
    if (items == null) {
      return "";
    }
    return items.length + "_" + items.map(a => a.label + "_" + a.disabled + "_" + this.getItemsKeys(a.items)).join("|");
  }

  toggleMenu() {
    this.hidden = !this.hidden;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostBinding('style.float')
  get float() {
    return this.type == "Side" ? "left" : "";
  }

  @HostBinding('style.height')
  get height() {
    return this.type == "Side" ? "100vh" : "";
  }

  @HostBinding('style.margin-right')
  get margin() {
    return this.type == "Side" ? "20px" : "";
  }

  @HostBinding('style.position')
  get position() {
    return this.type == "Side" ? "sticky" : "";
  }

  @HostBinding('style.top')
  get top() {
    return this.type == "Side" ? "0" : "";
  }
}
