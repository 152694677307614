import { Injectable, Inject } from '@angular/core';
import { ZAPPDEV_DOCUMENT } from '../theme.options';

@Injectable()
export class ZAppDevSpinnerService {

  private loaders: Promise<any>[] = [];
  private selector = 'zapp-global-spinner';
  private loadingTimeoutId: any;
  private spinners: number[] = [];

  constructor(@Inject(ZAPPDEV_DOCUMENT) private document) {}

  registerLoader(method: Promise<any>): void {
    this.loaders.push(method);
  }

  clear(): void {
    this.loaders = [];
  }

  load(): void {
    this.loadingTimeoutId = setTimeout(() => {
      this._showSpinner();
    }, 600);
    
    this.executeAll();
  }

  private executeAll(done = () => {}): void {
    Promise.all(this.loaders).then((values) => {
      this.hideSpinner();
      done.call(null, values);
    })
      .catch((error) => {
        console.error(error);
      });
  }

  showSpinner(): void {
    clearTimeout(this.loadingTimeoutId);
    this.spinners.push(1);
    this.loadingTimeoutId = setTimeout(() => {
      this._showSpinner();
    }, 400);
  }

  private _showSpinner(): void {
    const el = this.getSpinnerElement();
    if (el) {
      el.style.display = 'block';
    }
  }

  hideSpinner(): void {
    clearTimeout(this.loadingTimeoutId);
    this.spinners.pop();
    if (this.spinners.length > 0) {
      return;
    }
    
    const el = this.getSpinnerElement();
    if (el) {
      el.style.display = 'none';
    }
  }

  private getSpinnerElement() {
    return this.document.getElementById(this.selector);
  } 
}
