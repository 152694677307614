import * as moment from 'moment';
import { System } from './clms.fw.dotnet';
import { IValidationItem } from '../interfaces/validation-item.interface';
import * as numeral from 'numeral';

export namespace Joove {
  export interface IContext {
    appName?:string;
    mode?:string;
    isDirty?:boolean;
    siteRoot?:string;
    currentLocale?: Locale;
    currentLanguage?: any;
    currentAction?:string;
    currentController?:string;
    currentMasterPageController?:string;
    currentMasterPageObject?:any;
    currentUsername?:string;
    currentUserRoles?:Array<string>;
    currentUserPermissions?:Array<string>;
    currentUser?: any;
    theme?: string;
    isModal?:boolean;
    language?:string;
    languageId?:string;
    languageName?:string;
    locale?:string;
    localeId?:string;
    localeName?:string;
    longDatePattern?:string;
    shortDatePattern?:string;
    longTimePattern?:string;
    yearMonthPattern?:string;
    RFC1123Pattern?:string;
    monthDayPattern?:string;
    shortTimePattern?:string;
    decimalSeparator?:string;
    groupSeparator?:string;
    currentVersion?:string;
    urlQuery?:string;
    zipRequests?:boolean;
    routeData?:string;
    ajaxContentType?:string;
    adminCanSetPasswords?:boolean;
    sessionTimeOutMins?: number;
    appSettings?: any;
  }

  export class Session {
    public static CurrentLanguage() {
      return {
        Id: window._context?.currentLanguage?.languageId,
        Code: window._context?.currentLanguage?.language,
        Name: window._context?.currentLanguage?.languageName,
        DateTimeFormat: {
          LongDatePattern: window._context?.currentLocale?.longDatePattern,
          LongTimePattern: window._context?.currentLocale?.longTimePattern,
          MonthDayPattern: window._context?.currentLocale?.monthDayPattern,
          RFC1123Pattern: window._context?.currentLocale?.RFC1123Pattern,
          ShortDatePattern: window._context?.currentLocale?.shortDatePattern,
          ShortTimePattern: window._context?.currentLocale?.shortTimePattern,
          YearMonthPattern: window._context?.currentLocale?.yearMonthPattern,
        }
      };
    };

    public static CurrentLocale() {
      return {
        Id: window._context?.currentLanguage?.localeId,
        Code: window._context?.currentLanguage?.locale,
        Name: window._context?.currentLanguage?.localeName,
        DateTimeFormat: {
          LongDatePattern: window._context?.currentLocale?.longDatePattern,
          LongTimePattern: window._context?.currentLocale?.longTimePattern,
          MonthDayPattern: window._context?.currentLocale?.monthDayPattern,
          RFC1123Pattern: window._context?.currentLocale?.RFC1123Pattern,
          ShortDatePattern: window._context?.currentLocale?.shortDatePattern,
          ShortTimePattern: window._context?.currentLocale?.shortTimePattern,
          YearMonthPattern: window._context?.currentLocale?.yearMonthPattern,
        }
      };
    };

    private static SessionTimeOut: any;

    private static SessionAboutToExpireTimeOut: any;

    public static ResetSessionExpirationTimeOut() {
      clearTimeout(Session.SessionAboutToExpireTimeOut);
      clearTimeout(Session.SessionTimeOut);

      if (window._context.currentUsername == null || window._context.currentUsername == "") return;

      const msBeforeExpirationToShowWarning = 30 * 1000; // 30 seconds
      const expireDurationInMs = window._context.sessionTimeOutMins * 60 * 1000;
      const aboutToExpireDurationInMs = expireDurationInMs - msBeforeExpirationToShowWarning;

      /*
      This is a temp fix
      Session.SessionAboutToExpireTimeOut = setTimeout(() => {
          Session.ShowSessionIsAboutToExpireModal();
      }, aboutToExpireDurationInMs);

      Session.SessionTimeOut = setTimeout(() => {
          $.connection['eventsHub'].connection.stop();
          Session.ShowSessionExpiredModal();
      }, expireDurationInMs);
      */
    }

    private static ShowSessionIsAboutToExpireModal() {
      //window._popUpManager.warning("", window._resourcesManager.getSesionAboutToExpireMessage(), () => {
      //  Session.RefreshSession();
      //});
    }

    private static ShowSessionExpiredModal() {
      //window._popUpManager.error("", window._resourcesManager.getSesionExpiredMessage());
    }

    private static RefreshSession() {
      Session.PingServer(() => {
        //window._popUpManager.success("", window._resourcesManager.getSesionRefreshedMessage());
      });
    }

    public static SessionConflict() {
      clearTimeout(Session.SessionAboutToExpireTimeOut);
      clearTimeout(Session.SessionTimeOut);

      //window._popUpManager.alert('', `User <b>${window._context.currentUsername}</b> has signed in from another device.<br/><br/> Please refresh page to sign in again.`);
    }

    public static PingServer(cb: Function) {
      //Core.executeControllerActionNew({
      //  verb: "GET",
      //  action: "_ping",
      //  controller: window._context.currentMasterPageController,
      //  cb: () => {
      //    cb && cb();
      //  }
      //});
    }

  }


  export interface IDataValidationsState {
    validations: IValidationItem[];
  }

  export enum Placement {
    TOP,
    TOP_RIGHT,
    TOP_LEFT,
    RIGHT,
    LEFT,
    BOTTOM_RIGHT,
    BOTTOM_LEFT,
    BOTTOM
  }

  export enum MambaDataType {
    COLLECTION,
    DICTIONARY,
    FUNC,
    COLLECTIONBASE,
    STRING,
    BOOL,
    INT,
    DOUBLE,
    DECIMAL,
    FLOAT,
    LONG,
    DATETIME,
    CHAR,
    GUID,
    BYTE,
    OBJECT,
    RUNTIMETYPE,
    RUNTIMEPROPERTY,
    EXCEPTION,
    TIMESPAN,
    BUSINESSEXCEPTION,
    NUMBER
  }

  export enum MessageType {
    Success = 0,
    Error = 1,
    Warning = 2,
    Info = 3
  }

  export enum FormRenderPosition {
    NEW_TAB = 0,
    MODAL,
    TOP_SLIDE,
    BOTTOM_SLIDE,
    LEFT_SLIDE,
    RIGHT_SLIDE
  }

  export type Locale = {
    RFC1123Pattern: string,
    locale: string,
    localeId: number,
    localeName: string,
    longDatePattern: string,
    longTimePattern: string,
    monthDayPattern: string,
    shortDatePattern: string,
    shortTimePattern: string,
    yearMonthPattern: string,
  }

  var nextUniqueId = (() => {
    var currentId = 1;

    return () => (currentId++);

  })();

  export enum ScriptState {
    LOADED,
    LOADING
  }

  export class ScriptLoaderManager {
    constructor(private scriptsStates) {
      this.scriptsStates = {};
    }

    requireScript(id: string, src: string, callback?: () => void): Promise<boolean> {
      if (this.scriptsStates[id] === ScriptState.LOADED) {
        return new Promise<boolean>((resolve) => {
          resolve(true);
        });
      }

      this.scriptsStates[id] = ScriptState.LOADING;

      return new Promise<boolean>((resolve) => {
        const script = document.createElement("script");
        script.setAttribute("src", src);

        const onLoad = (): any => {
          this.scriptsStates[id] = ScriptState.LOADED;
          callback && callback();
          resolve(true);
        };

        script.onload = onLoad;
        document.head.appendChild(script);
      });
    }

    isScriptLoaded(id: string): boolean {
      return this.scriptsStates[id] === ScriptState.LOADED;
    }

    private waitForScriptLoad(lookFor: string, callback: () => void) {
      var interval = setInterval(() => {

        if (eval(`typeof ${lookFor}`) !== "undefined") {
          clearInterval(interval);
          callback();
        }

      }, 50);

    }
  }

  export class Logger {
    static info(...args: any[]): void {
      //CLMS.Framework.Utilities.DebugHelper.Instance().Info(args);
    }

    static debug(...args: any[]): void {
      //CLMS.Framework.Utilities.DebugHelper.Instance().Debug(args);
    }

    static error(...args: any[]): void {
      //CLMS.Framework.Utilities.DebugHelper.Instance().Error(args);
    }
  }

  export class Comparator {
    static DeepEqual(left: any, right: any, skipProperty: Function = null): boolean {
      if (left === right) return true;

      const arrLeft = Array.isArray(left);
      const arrRight = Array.isArray(right);

      if (arrLeft != arrRight) {
        Joove.Logger.info("Array is not equal", left, right);
        return false;
      }

      if (arrLeft && arrLeft) {
        let length = left.length;
        if (length != right.length) {
          return false;
        }
        for (let i = 0; i < length; i++) {
          if (!Comparator.DeepEqual(left[i], right[i], skipProperty)) {
            Joove.Logger.info("Array is not equal", left[i], right[i]);
            return false;
          }
        }
        return true;
      }

      const dateLeft = left instanceof Date;
      const dateRight = right instanceof Date;

      if (dateLeft != dateRight) return false;
      if (dateLeft && dateRight) return left.getTime() == right.getTime();

      const regexpLeft = left instanceof RegExp;
      const regexpRight = right instanceof RegExp;
      if (regexpLeft != regexpRight) return false;
      if (regexpLeft && regexpRight) return left.toString() == right.toString();

      if (left instanceof Object && right instanceof Object) {
        const keys = Object.keys(left);
        const length = keys.length;

        if (length !== Object.keys(right).length) {
          Joove.Logger.info("Object is not equal", left, right);
          return false;
        }

        for (let i = 0; i < length; i++) {
          if (!Object.prototype.hasOwnProperty.call(right, keys[i])) {
            Joove.Logger.info("Property is missing", right, keys[i]);
            return false;
          }
        }

        for (let i = 0; i < length; i++) {
          const key = keys[i];
          if (skipProperty != null && skipProperty(key)) {
            continue;
          }
          if (!Comparator.DeepEqual(left[key], right[key], skipProperty)) {
            Joove.Logger.info("Object is not equal", left[key], right[key]);
            return false;
          }
        }

        return true;
      }
      return false;
    }

    static IsEqual(left: any, right: any, type: string): boolean {
      switch (type) {
        case "bool":
        case "int":
        case "float":
        case "double":
        case "decimal":
        case "char":
          return String(left) === String(right);
        default:
          return left == right;
      }
    }
  }

  export class Common {
    public static safeDeepPropertySet(obj: any, key: Array<string> | string, val: any): void {
      let keys = typeof key === "string" ? key.split('.') : key;
      let i = 0, l = keys.length, t = obj, x;
      for (; i < l; ++i) {
        x = t[keys[i]];
        t = t[keys[i]] = (i === l - 1 ? val : (x == null ? {} : x));
      }
    }

    public static safeDeepPropertyAccess(obj: any, key: string, def?: any): any {
      let p = 0;
      let keys = key.split ? key.split('.') : key;
      while (obj && p < keys.length) obj = obj[keys[p++]];
      return obj === undefined ? def : obj;
    }

    static setNumberLocalizationSettings() {
      if (numeral["locales"] == null || numeral["locales"]["_custom"] != null) return;
      numeral.register('locale', '_custom', {
        delimiters: {
            thousands: window._context.groupSeparator,
            decimal: window._context.decimalSeparator
        },
        abbreviations: {
            thousand: 'k',
            million: 'm',
            billion: 'b',
            trillion: 't'
        },
        ordinal : function (number) {
            return number === 1 ? 'er' : 'ème';
        },
        currency: {
            symbol: '€'
        }
    });
    
    // switch between locales
    numeral.locale('_custom');
    }

    static project(model: any, schema: any, indexes: Array<number> = null, iteration: number = null, ifEmptyFetchAll = false) {
      if (model == null || schema == null) {
        return null;
      }

      if (Common.valueIsPrimitive(model)) {
        return model;
      }

      const projectedModel = {
      };

      let projectSubset = false;
      if (indexes != null) {
        if (iteration == null) iteration = 0;
        projectSubset = true;
      }

      if (ifEmptyFetchAll === true) {
        if (Common.isEmptyObject(schema)) {
          return model;
        }
      }

      for (var prop in schema) {
        if (model[prop] === undefined || schema[prop] === null) {
          continue;
        }

        if (model[prop] == null) {
          projectedModel[prop] = null;
          continue;
        }

        if (Common.isArray(model[prop])) {
          const newArr = [];
          for (let c = 0; c < model[prop].length; c++) {
            if (projectSubset == false || c == indexes[iteration]) {
              newArr.push(Common.project(model[prop][c], schema[prop], indexes, iteration + 1, ifEmptyFetchAll));
            }
          }
          projectedModel[prop] = newArr;
        } else if (Common.valueIsObject(model[prop])) {
          projectedModel[prop] = Common.project(model[prop], schema[prop], indexes, iteration, ifEmptyFetchAll);
        } else {
          projectedModel[prop] = model[prop];
        }
      }

      return projectedModel;
    }

    static isEmptyObject(obj: any) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }
      return true;
    }

    static dateDiff(date1: Date, date2: Date): System.Time.TimeSpan {
      var momentDate1 = moment(date1);
      var momentDate2 = moment(date2);
      return new System.Time.TimeSpan(momentDate1.diff(momentDate2));
    }

    static stringContains(str: string, otherStr: string, caseInsensitive = true): boolean {
      if (str == null) {
        return false;
      }
      return caseInsensitive
        ? str.toLowerCase().indexOf(otherStr.toLowerCase()) !== -1
        : str.indexOf(otherStr) !== -1;
    }

    static stringIsNullOrEmpty(str: string): boolean {
      return str == null || str === "";
    }

    static stringIsNullOrWhiteSpace(str: string): boolean {
      return str == null || `${str}`.trim().length <= 0;
    }

    static stringEndsWith(str: string, suffix: string): boolean {
      return str.indexOf(suffix, this.length - suffix.length) !== -1;
    }

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round
    static decimalAdjust(type: any, value: number, exp?: number): number {
      // If the exp is undefined or zero...
      if (typeof exp === 'undefined' || +exp === 0) {
        return Math[type](value);
      }
      value = +value;
      exp = +exp;
      // If the value is not a number or the exp is not an integer...
      if (value === null || isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN;
      }
      // If the value is negative...
      if (value < 0) {
        return -Common.decimalAdjust(type, -value, exp);
      }
      // Shift
      value = value.toString().split('e') as any;
      value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
      // Shift back
      value = value.toString().split('e') as any;
      return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
    }

    static round(value: number, decimals: number): number {
      return Common.decimalAdjust("round", value, decimals);
    }

    static ceil(value: number, decimals: number): number {
      return Common.decimalAdjust("ceil", value, decimals);
    }

    static floor(value: number, decimals: number): number {
      return Common.decimalAdjust("floor", value, decimals);
    }

    static mathRound(value, decimals) {
      var multiplier = Math.pow(10, decimals || 0);
      return Math.round(value * multiplier) / multiplier;
    }

    static sign(x: any): number {
      return (Number(x > 0) - Number(x < 0)) || +x;
    }

    static pathJoin(...args) {
      return args.map((part, i) => {
        if (i === 0) {
          return part.trim().replace(/[\/]*$/g, '')
        } else {
          return part.trim().replace(/(^[\/]*|[\/]*$)/g, '')
        }
      }).filter(x => x.length).join('/')
    }

    static createDate(year: number, month: number, day: number, hours?: number, minutes?: number, seconds?: number):
      Date {
      const date = new Date();

      date.setFullYear(year, month, day);

      if (hours != null) {
        date.setHours(hours);
      }
      if (minutes != null) {
        date.setMinutes(minutes);
      }
      if (seconds != null) {
        date.setSeconds(seconds);
      }

      return date;
    }

    static createEvent(eventName: string): any {
      if (typeof (Event) === 'function') { //For normal browsers
        return new Event(eventName);
      } else {
        //For retarded IE
        var event = document.createEvent('Event');
        event.initEvent(eventName, true, true);
        return event;
      }
    }
    static isVisibleOnViewPort(element: HTMLElement): Placement {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      const elementRectangle = element.getBoundingClientRect();

      if (elementRectangle.left < windowHeight - elementRectangle.width) {

      } else {

      }

      return null;
    }

    static toggleDebugMode(enable: boolean) {
      localStorage.setItem("__debug", enable + "");
    }
    static isInDebugMode() {
      return localStorage.getItem("__debug") == "true";
    }

    static getMatch(verbalExpression: RegExp, input: string, defaultValue: string = null): string {
      try {
        let result = verbalExpression.exec(input);
        if (result && result.length > 0) {
          return result[0];
        }
        if (defaultValue) {
          return defaultValue;
        }

        return null;

      } catch (e) {
        if (defaultValue) {
          return defaultValue;
        }
        console.error(e);
      }
    }//end getMatch()

    static getMatches(verbalExpression: RegExp, input: string, defaultValues: Array<string> = null): Array<string> {
      try {
        let matches = input.match(verbalExpression);
        if (matches && matches.length > 0) {
          return matches;
        }
        if (defaultValues) {
          return defaultValues;
        }

        return null;

      } catch (e) {
        if (defaultValues) {
          return defaultValues;
        }
        console.error(e);
      }
    }//end getMatches()   

    static isValidEmail(str: string): boolean {
      if (str == null) return false;
      const re =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      return re.test(str);
    }

    static isValidUrl(str): boolean {
      if (str == null) return false;
      const pattern = new RegExp(
        "^" +
        // protocol identifier
        "(?:(?:https?|ftp)://)" +
        // user:pass authentication
        "(?:\\S+(?::\\S*)?@)?" +
        "(?:" +
        // IP address exclusion
        // private & local networks
        "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
        "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
        "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
        // IP address dotted notation octets
        // excludes loopback network 0.0.0.0
        // excludes reserved space >= 224.0.0.0
        // excludes network & broacast addresses
        // (first & last IP address of each class)
        "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
        "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
        "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
        "|" +
        // host name
        "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
        // domain name
        "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
        // TLD identifier
        "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
        // TLD may end with dot
        "\\.?" +
        ")" +
        // port number
        "(?::\\d{2,5})?" +
        // resource path
        "(?:[/?#]\\S*)?" +
        "$", "i"
      );

      if (!pattern.test(str)) {
        return false;
      } else {
        return true;
      }
    }

    static valueIsObject(value: any): boolean {
      return typeof value === "object" &&
        value !== null &&
        ((value instanceof Boolean) === false) &&
        ((value instanceof Date) === false) &&
        ((value instanceof Number) === false) &&
        ((value instanceof RegExp) === false) &&
        ((value instanceof String) === false);
    }

    static valueIsPrimitive(value: any): boolean {
      return !Common.valueIsObject(value) && !Common.isArray(value);
    }

    static setKeyAsClientKey(obj: any) {
      if (obj == null || obj._clientKey != null || obj._key == null) return;

      obj._clientKey = obj._key;
    }

    static objectsAreEqual(objA: any, objB: any, typeCheck?: boolean): boolean {
      Common.setKeyAsClientKey(objA);
      Common.setKeyAsClientKey(objB);

      // Type Check
      if (typeCheck === true && objA != null && objB != null && objA._typeHash != objB._typeHash) return false;

      // Simple value equality
      if (objA === objB) return true;

      //handle case where one is null and other is undefined
      if ((objA == null || objA == undefined) && (objB == null || objB == undefined)) return true;

      // Client Key equality
      if ((objA != null && objB != null) &&
        (objA._clientKey || objB._clientKey) &&
        objB._clientKey === objA._clientKey) return true;

      // One of objects null, other not null
      if (objA == null || objB == null) return false;

      // Key equality
      if (typeof (objB._key) != "undefined" &&
        Common.keyHasDefaultValue(objA._key) === false &&
        objB._key === objA._key) return true;

      // Angular $$haskey equality
      if (typeof (objB.$$hashKey) != "undefined" && objB.$$hashKey === objA.$$hashKey) return true;

      return false;
    }

    static objectsAreEqualGenericDeepComparison = function (a, b) {
      // If a and b reference the same value, return true
      if (a === b) return true;

      // If a and b aren't the same type, return false
      if (typeof a != typeof b) return false;

      // Already know types are the same, so if type is number
      // and both NaN, return true
      if (typeof a == 'number' && isNaN(a) && isNaN(b)) return true;

      // Get internal [[Class]]
      var aClass = Object.prototype.toString.call(a);
      var bClass = Object.prototype.toString.call(b);

      // Return false if not same class
      if (aClass != bClass) return false;

      // If they're Boolean, String or Number objects, check values
      if (aClass == '[object Boolean]' || aClass == '[object String]' || aClass == '[object Number]') {
        return a.valueOf() == b.valueOf();
      }

      // If they're RegExps, Dates or Error objects, check stringified values
      if (aClass == '[object RegExp]' || aClass == '[object Date]' || aClass == '[object Error]') {
        return a.toString() == b.toString();
      }

      // Otherwise they're Objects, Functions or Arrays or some kind of host object
      if (typeof a == 'object' || typeof a == 'function') {
        // For functions, check stringigied values are the same
        // Almost certainly false if a and b aren't trivial
        // and are different functions
        if (aClass == '[object Function]' && a.toString() != b.toString()) return false;

        var aKeys = Object.keys(a);
        var bKeys = Object.keys(b);

        // If they don't have the same number of keys, return false
        if (aKeys.length != bKeys.length) return false;

        // Check they have the same keys
        if (!aKeys.every(function (key) { return b.hasOwnProperty(key) })) return false;

        // Check key values - uses ES5 Object.keys
        return aKeys.every(function (key) {
          return Common.objectsAreEqualGenericDeepComparison(a[key], b[key]);
        });
      }
      return false;
    }

    // TODO: Check that key has value not only by "0" but also for each Datatype...
    static keyHasDefaultValue = function (key): boolean {
      if (key == null) return true;

      var keyAsString = key.toString().trim();

      return keyAsString == "0" || keyAsString == "" || keyAsString == "00000000-0000-0000-0000-000000000000";
    }

    static changeDateTimeFormat = function (formatString: string): string {
      let val = formatString.replace(new RegExp('tt', 'g'), 'a');

      val = val.replace(new RegExp('d', 'g'), 'D');
      val = val.replace(new RegExp('y', 'g'), 'Y');
      val = val.replace(new RegExp('f', 'g'), 'S');

      return val;
    }

    static classInstancesAreSame = function (objectA, objectB) {
      if ((objectA["_originalTypeClassName"] != null && objectB["_originalTypeClassName"] != null) &&
        (objectA["_originalTypeClassName"] !== objectB["_originalTypeClassName"])) {
        return false;
      }

      if (objectA["_clientKey"] != null && objectA["_clientKey"] === objectB["_clientKey"]) return true;

      if ((objectA["_key"] != null && objectA["_key"] !== 0 && objectA["_key"] !== "" && objectA["_key"] === objectB["_key"])) return true;

      return false;
    };

    static getClassInstanceKey(instance: any) {
      if (instance["_originalTypeClassName"] == null) {
        return null;
      }

      var key = instance["_originalTypeClassName"];
      if (instance["_clientKey"] != null) {
        return key + "_" + instance["_clientKey"];
      }

      if (instance["_key"] == null || instance["_key"] == 0 || instance["_key"] == "") {
        return null;
      }

      return key + "_" + instance["_key"];
    }

    static classInstancesAreNotSame = function (objectA, objectB) {
      return Common.classInstancesAreSame(objectA, objectB) === false;
    };

    static collectionsAreEqual(collectionA: Array<any>, collectionB: Array<any>): boolean {
      // Both null
      if (collectionA == null && collectionB == null) return true;

      // One  null, other not null
      if ((collectionA == null && collectionB != null) || (collectionA != null && collectionB == null)
      ) return false;

      // Different Length, impossible to be equal
      if (collectionA.length != collectionB.length) return false;

      for (let i = 0; i < collectionA.length; i++) {
        if (Common.objectsAreEqual(collectionA[i], collectionB[i]) === false) return false;
      }

      return true;
    }

    static replaceAll(value: string, search: string, replacement: string): string {
      return value == null
        ? null
        : value.split(search).join(replacement);
    }

    static serializeIndexes(indexes: Array<string>): string {
      let indexKey = "";

      for (let j = 0; j < indexes.length; j++) {
        indexKey += indexes[j] + "_";
      }

      if (indexKey === "") {
        indexKey = "_";
      }

      return indexKey;
    }

    static formatDate(value: Date, format: string): string {
      //format = format.toUpperCase();

      const momentDate = moment(value);

      return momentDate.isValid()
        ? momentDate.format(format)
        : "";
    }

    static getUtcDateFromRawString(rawValue: string, fullFormat: string, setToMidday?: boolean): Date {
      if (rawValue == null || rawValue.trim() === "" || rawValue === "Invalid date") {
        return null;
      } else {
        const localTime = moment(rawValue, fullFormat);

        if (setToMidday === true) {
          localTime.set("hours", 12);
          localTime.set("minutes", 0);
        }

        const utcTime = moment.utc(localTime);

        return utcTime.toDate();
      }
    }

    static getDateStringFromUtc(value: Date, fullFormat: string): string {
      if (value == null) {
        return "";
      } else {
        const utcTime = moment.utc(value).toDate();
        const localTime = moment(utcTime);

        return localTime.format(fullFormat);
      }
    }

    static trackObject(obj: any): number {
      if (Common.valueIsObject(obj) === false || obj == null) return obj;

      if (obj._clientKey == null) {
        obj._clientKey = (nextUniqueId() * -1);
      }

      return obj._clientKey;
    }

    // todo: extend this method with more cases
    /*static eventPreventsDefaultFormAction(e: JQueryEventObject): boolean {
        const $target = $(e.target);

        if (window._popUpManager.popUpVisible || (window._popUpManager.isLoading)) return;

        if ($target["context"] != null) {
          const tagName = $target["context"].tagName.toLowerCase();
            if (
                (tagName == "trix-editor") ||
                (tagName == "textarea") || 
                (tagName == "button" && e.which === 13)
            ) 
    {
                return true;
            }
        }

        if ($target.parent().hasClass("chosen-search")) { // chosen select search active
            $target.blur();
            return true;
        }

        if ($target.hasClass("quick-filter")) {
            return false;
        }

        return false;
    }*/

    static setControlKeyPressed(e: KeyboardEvent, duration: number) {
      window["ctrlPressed"] = e != null && e.ctrlKey;

      setTimeout(() => {
        window["ctrlPressed"] = false;
      }, 1000);
    }

    static controlKeyWasPressed(): boolean {
      return window["ctrlPressed"] === true;
    }

    static setLastClickedElement(e: MouseEvent) {
      window["lastClickedElement"] = e == null || e.target == null ? null : e.target;
    }

    static getLastClickedElement(): Element {
      return window["lastClickedElement"];
    }

    static detectBrowser(): string {
      const isOpera = !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;
      // Opera 8.0+ (UA detection to detect Blink/v8-powered Opera)
      const isFirefox = typeof window.InstallTrigger !== "undefined"; // Firefox 1.0+
      const isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf("Constructor") > 0;
      // At least Safari 3+: "[object HTMLElementConstructor]"
      const isChrome = !!window.chrome && !isOpera; // Chrome 1+
      const isInternetExplorer = /*@cc_on!@*/false || !!document["documentMode"]; // At least IE6

      if (isOpera) return "Opera";
      else if (isFirefox) return "Firefox";
      else if (isSafari) return "Safari";
      else if (isChrome) return "Chrome";
      else if (isInternetExplorer) return "IE";
      else return "";
    }

    static getScrollbarSize(): number {
      const inner = document.createElement("p");
      inner.style.width = "100%";
      inner.style.height = "200px";

      const outer = document.createElement("div");
      outer.style.position = "absolute";
      outer.style.top = "0px";
      outer.style.left = "0px";
      outer.style.visibility = "hidden";
      outer.style.width = "200px";
      outer.style.height = "150px";
      outer.style.overflow = "hidden";
      outer.appendChild(inner);

      document.body.appendChild(outer);
      const w1 = inner.offsetWidth;
      outer.style.overflow = "scroll";
      let w2 = inner.offsetWidth;
      if (w1 === w2) w2 = outer.clientWidth;

      document.body.removeChild(outer);

      return (w1 - w2);
    };

    static toJson(data: string): JSON {
      return JSON.parse(data.replace(/'/g, "\"").replace(/[\t\n\r]/g, ""));
    }

    static forceNumberFormat(number: string): string {
      if (!number) return;
      return number.replace(",", ".").trim();
    }

    static parseFloat(number: string): number {
      let unsafeValue = parseFloat(Common.forceNumberFormat(number));
      if (isNaN(unsafeValue))
        unsafeValue = 0;

      return unsafeValue;
    }

    static convertToNumber(value: string): number {
      if (value.toLowerCase() === "nan")
        return NaN;
      const num = Number(value);
      return isNaN(num) ? null : num;
    }

    static convertToBoolean(value: string): boolean {
      value.toLowerCase();
      return (value === "true" || value === "false") ? value === "true" : null;
    }

    static convertToDateTime(value: string): Date {
      const convertDate = new Date(Date.parse(value));

      if (isNaN(convertDate.getTime())) return null;

      return convertDate;
    }

    static guessStringMambaDataType(value: string): MambaDataType {
      if (Common.convertToBoolean(value) !== null)
        return MambaDataType.BOOL;

      if (Common.convertToNumber(value) !== null)
        return MambaDataType.NUMBER;

      if (Common.convertToDateTime(value) !== null)
        return MambaDataType.DATETIME;

      return MambaDataType.STRING;
    }

    static getMambaDataType(type: string): MambaDataType {
      const localType = type.toUpperCase();

      if (localType === "INT" ||
        localType === "DOUBLE" ||
        localType === "DECIMAL" ||
        localType === "FLOAT" ||
        localType === "LONG" ||
        localType === "BYTE")
        return MambaDataType.NUMBER;

      return MambaDataType[localType];
    }

    /**
     * 
     * @param value 
     * @param seperators [ groupSep, decimalSep ] 
     */
    static parseToDecimal(value: any, seperators: Array<string>): { integerPart: string, fractionPart: string } {
      if (value == null) return null;
      value = value.toString();

      let thousands = seperators[0];
      let decimal = seperators[1];

      let groupMinSize = true ? 3 : 1;
      const pattern = new RegExp("^\\s*([+\-]?(?:(?:\\d{1,3}(?:\\" + thousands + "\\d{" + groupMinSize + ",3})+)|\\d*))(?:\\" + decimal + "(\\d*))?\\s*$");

      const result = value.match(pattern);
      if (!((result != null) && result.length === 3)) {
        return null;
      }
      const integerPart = result[1];
      // const integerPart = result[1].replace(new RegExp("\\" + thousands, 'g'), '');
      const fractionPart = result[2];

      return { integerPart, fractionPart };
    }

    /**
     * 
     * @param value 
     * @param seperators [ groupSep, decimalSep ] 
     */
    static localizeNumber(value: any, seperators: Array<string> = null): string {
      if (value == null) return null;

      var rawNumber = value;

      value = value.toString();

      // convert exponential format to 'normal' js number
      if (value != null && (value.indexOf("e") > -1 || value.indexOf("E") > -1)) {
        value = rawNumber.toFixed(20);
      }

      seperators = seperators || [".", ","];

      var decimal = Common.parseToDecimal(value, seperators);

      if (decimal == null) {
        seperators = [",", "."];
        decimal = Common.parseToDecimal(value, seperators);
      }

      if (decimal == null) throw { msg: "Number has an invalid format" };

      var integerPart = Common.replaceAll(decimal.integerPart, seperators[0], window._context.groupSeparator);
      if (decimal.fractionPart != null) {
        return `${integerPart}${window._context.decimalSeparator}${decimal.fractionPart}`;
      }
      return `${integerPart}`;
    }

    /**
     * 
     * @param value 
     * @param seperators [ groupSep, decimalSep ] 
     */
    static delocalizeNumber(value: any, seperators: Array<string> = null,): string {
      if (value == null) return null;

      value = value.toString();
      seperators = seperators || [window._context.groupSeparator, window._context.decimalSeparator];

      var decimal = Common.parseToDecimal(value, seperators);

      if (decimal == null) throw { msg: "Number has an invalid format" };

      var integerPart = Common.replaceAll(decimal.integerPart, seperators[0], "");
      if (decimal.fractionPart != null) {
        return `${integerPart}.${decimal.fractionPart}`;
      }
      return `${integerPart}`;
    }

    static getCurrenctNumberSeperator(): Array<string> {
      return [window._context.groupSeparator, window._context.decimalSeparator];
    }

    /**
     * 
     * @param value 
     * @param seperators [ groupSep, decimalSep ] 
     */
    static parseToNumber(value: any, seperators: Array<string> = null, enforceGroupSize: boolean = true): number {
      if (value == null) return null;
      if (typeof value === "number") return value;

      value = value.toString();
      seperators = seperators || [window._context.groupSeparator || ".", window._context.decimalSeparator || ","];

      var decimal = Common.parseToDecimal(value, seperators);

      if (decimal == null) return 0 / 0;

      const integerPart = decimal.integerPart.replace(new RegExp("\\" + seperators[0], 'g'), '');
      const parsedNumber = parseFloat(integerPart + "." + decimal.fractionPart);
      return parsedNumber;
    }

    static autoParse(value: string): any {
      const newValue = parseFloat(value);

      if (!isNaN(newValue)) return newValue;

      if (value === "true") return true;
      if (value === "false") return false;

      return value;
    }

    static isArray(instance: any): boolean {
      return instance != null && instance.constructor === Array && instance.length != null;
    }

    static safeNumber(num: any): number {
      if (num === undefined) {
        return 0;
      }

      return num;
    }

    static nullSafe<T>(expr: () => T, deffaultValue: T): T {
      try {
        return expr();
      } catch (e) {
        if (window._context.mode !== "Production") {
          console.warn(`Expression '${expr}' throws null point exception`);
        }
        return deffaultValue;
      }
    }

    //static autocompleteFix(): void {
    //  if ($("input[type='password'][ng-model]").length === 0) {
    //    return;
    //  }

    //  const inputs = [];
    //  inputs.push(...($("input[type='password'][ng-model]") as any));
    //  inputs.push(...($("input[jb-type='TextBox'][ng-model]") as any));

    //  const check = (input: Element) => {
    //    const $input = $(input);
    //    var model = $input.attr("ng-model");

    //    var expression = model.replace("model.", "Joove.Common.getModel().");

    //    try {
    //      if (eval(expression) !== $input.val()) {
    //        eval(expression + " = '" + $input.val() + "'");
    //      }
    //    }
    //    catch (e) {
    //      console.warn("Autocomplete error!", e);
    //    }
    //  }

    //  for (let i = 0; i < inputs.length; i++) {
    //    check(inputs[i]);
    //  }
    //}

    static createRandomId(length?: number): string {
      length = length || 5;
      var text = "";
      var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (var i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    }

    static requireScript(id, src, callback?): Promise<boolean> {
      return window._scriptLoader.requireScript(id, src, callback);
    }

    static usePDFMaker(options: { filename: string, dd: any, tableLayouts?: any }): void {
      if (window._scriptLoader.isScriptLoaded("pdfmake")) {
        pdfMake.createPdf(options.dd).download(options.filename);
      } else {
        Common.loadPdfMakeDependencies(options)
          .then(() => {
            if (options.tableLayouts) {
              pdfMake.tableLayouts = options.tableLayouts;
            }
            Common.usePDFMaker(options);
          });
      }
    }

    static loadPdfMakeDependencies(options: { filename: string }): Promise<boolean> {
      const src = window._context.siteRoot + "/Scripts/Third-Party/pdfmaker";
      return Common.requireScript("pdfmake", `${src}/pdfmake.min.js`)
        .then(() => {
          return Common.requireScript("vfs_fonts", `${src}/vfs_fonts.js`);
        }).then(() => {
          return true;
        });
    }

    //static loadJsPdfDependencies(options: { filename: string, $element?: HTMLElement, usePrintMedia?: boolean }): void {
    //  const src = window._context.siteRoot + "/Scripts/Third-Party/jspdf";
    //  Common.requireScript("jsPDF",
    //    `${src}/jspdf.min.js`,
    //    () => {
    //      Common.requireScript("html2pdf",
    //        `${src}/html2pdf.js`,
    //        () => {
    //          Common.exportToPdf(options);
    //        });
    //    });
    //}

    //static exportToPdfUsePrintMedia(target: HTMLElement, options: { filename: string, $element?: HTMLElement, usePrintMedia?: boolean }): void {
    //  const doc = new jsPDF("p", "pt", "letter");
    //  $(".jb-modal-overlay").show();
    //  html2pdf(target, doc, (pdf) => {
    //    pdf.save(options.filename);
    //    $(".jb-modal-overlay").hide();
    //  });
    //}

    //static exportToPdf(options: { filename: string, $element?: HTMLElement, usePrintMedia?: boolean }): void {

    //  $(".jb-modal-overlay").show();

    //  if (window["jsPDF"] == null) {
    //    Common.loadJsPdfDependencies(options);
    //  } else {
    //    const target = options.$element || $("body");

    //    if (options.usePrintMedia) {
    //      Common.exportToPdfUsePrintMedia(target, options);
    //      return;
    //    }

    //    const width = target.width();
    //    const height = target.height();
    //    let bgColor = "rgba(0, 0, 0, 0)";

    //    if (options.$element != null) {
    //      bgColor = options.$element.css("background-color");
    //      if (options.$element.css("background-color") === "rgba(0, 0, 0, 0)") {
    //        options.$element.css("background-color", $("body").css("background-color"));
    //      }
    //    }

    //    var doc = new jsPDF({
    //      orientation: "portrait",
    //      format: "custom",
    //      unit: "pt",
    //      pageWidth: width,
    //      pageHeight: height
    //    });

    //    // hide before adding body
    //    $(".jb-modal-overlay").hide();

    //    if (options.$element != null) {
    //      doc.addHTML(options.$element.get(0));
    //    } else {
    //      doc.addHTML($("body").get(0), 0, 0, {}, (w, cy, x, args) => {

    //      });
    //    }

    //    // add again
    //    $(".jb-modal-overlay").show();
    //    setTimeout(() => {
    //      doc.save(options.filename);
    //      $(".jb-modal-overlay").hide();
    //      if (options.$element != null) {
    //        options.$element.css("background-color", bgColor);
    //      }
    //    },
    //      5000);
    //  }
    //}

    static cast(instance, type) {
      if (instance == null || Common.stringIsNullOrEmpty(instance._originalTypeClassName)) {
        return instance;
      }

      //TODO: check inheritance list
      return instance._originalTypeClassName == type ? instance : null;
    }

    static findAncestor(el: HTMLElement, cls: string): HTMLElement {
      while ((el = el.parentElement) && !el.classList.contains(cls));
      return el;
    }
  }

  function Transform() {
    return (target, propertyKey: string, descriptor: PropertyDescriptor) => {
      try {
        const value = target[propertyKey];
        descriptor.value = () => {
          let val = value() as string;

          val = Joove.Common.changeDateTimeFormat(val);

          return val;
        }
      } catch (ex) {
        // 
      }

    }
  }

  export class DateTimeFormat {

    @Transform()
    LongDatePattern() {
      return window._context.longDatePattern;
    }

    @Transform()
    LongTimePattern() {
      return window._context.longTimePattern;
    }

    @Transform()
    ShortDatePattern() {
      return window._context.shortDatePattern;
    }

    @Transform()
    ShortTimePattern() {
      return window._context.shortTimePattern;
    }

    GeneralShortTimePattern() {
      return `${this.ShortDatePattern()} ${this.ShortTimePattern()}`;
    }

    GeneralLongTimePattern() {
      return `${this.ShortDatePattern()} ${this.LongTimePattern()}`;
    }
  }

  export class GlobalizationManager {
    private static _instance: GlobalizationManager;
    private dateTimeFormat: DateTimeFormat;

    static GetCurrentLocaleManager(): GlobalizationManager {
      if (GlobalizationManager._instance != null)
        return GlobalizationManager._instance;
      GlobalizationManager.init();
      return GlobalizationManager._instance;
    }

    get SortName() {
      return window._context.locale;
    }

    get SortNameLanguage() {
      return window._context.language;
    }

    get DateTimeFormat() {
      return this.dateTimeFormat;
    }

    private static init(): void {
      const manager = new GlobalizationManager();
      manager.dateTimeFormat = new DateTimeFormat();
      GlobalizationManager._instance = manager;
    }
  }

  //export interface IElementViewPortObserverOptions {
  //  $element: JQuery;
  //  interval: number;
  //  stopWhenEnters?: boolean;
  //  stopWhenExits?: boolean;
  //  onEnter?: Function;
  //  onExit?: Function;
  //  distanceThreshold?: number;
  //  visibilityCheck?: boolean;
  //}

  //export class ElementViewPortObserver {
  //  private $el: JQuery;
  //  private el: HTMLElement;
  //  private interval: number;
  //  private onEnterView: Function;
  //  private onExitView: Function;
  //  private intervalObject: any;
  //  private stopOnEnter: boolean;
  //  private stopOnExit: boolean;
  //  private visibilityCheck: boolean;
  //  private distanceThreshold: number;

  //  constructor(opts: IElementViewPortObserverOptions) {
  //    this.el = opts.$element.get(0);
  //    this.$el = opts.$element;
  //    this.interval = opts.interval || 1000;
  //    this.onEnterView = opts.onEnter;
  //    this.onExitView = opts.onExit;
  //    this.stopOnEnter = opts.stopWhenEnters;
  //    this.stopOnExit = opts.stopWhenExits;
  //    this.visibilityCheck = opts.visibilityCheck;
  //    this.distanceThreshold = opts.distanceThreshold || 100;
  //  }

  //  public start() {
  //    this.onLoop();

  //    this.intervalObject = setInterval(() => {
  //      this.onLoop();
  //    }, this.interval);
  //  }

  //  public stop() {
  //    clearInterval(this.intervalObject);
  //  }

  //  private onLoop() {
  //    var isVisible = this.visibilityCheck === false
  //      ? true
  //      : this.$el.is(":visible") === true;

  //    var isInView = isVisible === false
  //      ? false
  //      : this.isInViewPort() === true;

  //    if (isVisible && isInView) {
  //      this.onEnterView && this.onEnterView();

  //      if (this.stopOnEnter === true) {
  //        this.stop();
  //      }
  //    }
  //    else {
  //      this.onExitView && this.onExitView();

  //      if (this.stopOnExit === true) {
  //        this.stop();
  //      }
  //    }
  //  }

  //  private isInViewPort(): boolean {
  //    var bounding = this.el.getBoundingClientRect();
  //    var distanceVertical = bounding.top - (window.innerHeight || document.documentElement.clientHeight);
  //    var distanceHorizontal = bounding.left - (window.innerWidth || document.documentElement.clientWidth);

  //    return bounding.top >= 0 && bounding.left >= 0 && distanceVertical <= this.distanceThreshold && distanceHorizontal <= this.distanceThreshold;
  //  }
  //}

  //export class LazyImageLoader {
  //  private observer: ElementViewPortObserver;
  //  private $img: JQuery;
  //  private checkVisibility: boolean;

  //  constructor($img: JQuery, checkVisibility: boolean) {
  //    this.$img = $img;
  //    this.observer = new ElementViewPortObserver({
  //      $element: this.$img,
  //      interval: 1000,
  //      stopWhenEnters: true,
  //      visibilityCheck: checkVisibility === true,
  //      onEnter: () => {
  //        this.setImage();
  //      }
  //    });
  //    this.observer.start();
  //  }

  //  private setImage() {
  //    var src = this.$img.data("src");
  //    this.$img.attr("src", src);
  //  }

  //}

  /*
  interface JQueryStatic {
    datetimepicker;
  }

  interface JQuery {
    combobox;
  }*/

  interface Document {
    documentMode: boolean;
  }

  interface Error {
    stack?: string;
  }

  interface Math {
    round10: (value: number, exp: number) => number;
    floor10: (value: number, exp: number) => number;
    ceil10: (value: number, exp: number) => number;
    sign: (x: number) => number;
  }

  declare const jsPDF: any;
  declare const html2pdf: any;
  declare const pdfMake: any;

  export class Cycles {
    static referencesMap = {};


    static nextUniqueId = (() => {
      var currentId = 1;
      return () => (currentId++);
    })();

    static reconstructObject(object: any): any {
      Cycles.mapObjectReferences(object);
      Cycles.restoreObjectReferences(object);

      return object;
    }

    static mapObjectReferences(object: any): any {
      "use strict";

      return (function terez(value) {
        // Not an object
        if (Common.valueIsObject(value) === false) return value;

        // Array, loop through its values
        if (Object.prototype.toString.apply(value) === "[object Array]") {
          for (let i = 0; i < value.length; i += 1) {
            terez(value[i]);
          }
        }
        // Object
        else {
          if (typeof (value["$id"]) != "undefined") {
            Cycles.referencesMap[value["$id"]] = value;
            delete value.$id;
          }

          for (let name in value) {
            if (Object.prototype.hasOwnProperty.call(value, name)) {
              terez(value[name]);
            }
          }
        }

        return value;

      }(object));
    }

    static checkForDuplicateIds(object: any): any {
      "use strict";

      var ids = [];

      return (function terez(value) {
        // Not an object
        if (Common.valueIsObject(value) === false) return value;

        // Array, loop through its values
        if (Object.prototype.toString.apply(value) === "[object Array]") {
          for (let i = 0; i < value.length; i += 1) {
            terez(value[i]);
          }
        }
        // Object
        else {
          if (typeof (value["$id"]) != "undefined") {
            if (ids.indexOf(value["$id"]) > -1) {
              console.log("Duplicate $id: " + value["$id"]);
              throw "$id already encountered!";
            }
            else {
              ids.push(value["$id"]);
            }
          }

          for (let name in value) {
            if (Object.prototype.hasOwnProperty.call(value, name)) {
              terez(value[name]);
            }
          }
        }

        return value;

      }(object));
    }

    static restoreObjectReferences(object: any): any {
      "use strict";

      return (function terez(value) {
        // Not an object
        if (Common.valueIsObject(value) === false) return value;

        // Array, loop through its values
        if (Object.prototype.toString.apply(value) === "[object Array]") {
          for (let i = 0; i < value.length; i += 1) {
            value[i] = terez(value[i]);
          }
        }
        // Object
        else {
          if (typeof (value["$ref"]) != "undefined") {
            return Cycles.referencesMap[value["$ref"]];
          }

          for (let name in value) {
            if (Object.prototype.hasOwnProperty.call(value, name)) {
              value[name] = terez(value[name]);
            }
          }
        }

        return value;

      }(object));
    }

    static decycleObject(object: any, refs?: any): any {
      "use strict";

      var objects = refs == null ? [] : refs;

      return (function terez(value) {
        // Not an object
        if (Common.valueIsObject(value) === false) return value;

        // Object has already been seen, switch value with a reference
        if (Object.prototype.toString.apply(value) !== "[object Array]") {
          for (let jj = 0; jj < objects.length; jj++) {
            if (Common.objectsAreEqual(objects[jj], value, true)) {
              return { $ref: objects[jj].$id };
            }
          }
        }

        // Array, loop through its values
        if (Object.prototype.toString.apply(value) === "[object Array]") {
          var newArr = [];
          for (let i = 0; i < value.length; i++) {
            newArr[i] = terez(value[i]);
          }
          return newArr;
        }
        // Object
        else {
          if (typeof (value.$ref) == "undefined" && typeof (value.$id) == "undefined") {
            value.$id = Cycles.nextUniqueId().toString();
          }

          objects.push(value);

          for (let propName in value) {
            if (value.hasOwnProperty(propName)) {

              if (propName.indexOf("$$hashKey") === 0) {
                delete value[propName];
                continue;
              }

              if (Object.prototype.hasOwnProperty.call(value, propName)) {
                value[propName] = terez(value[propName]);
              }
            }
          }
        }

        return value;

      }(object));
    }
  }
}
