import * as moment from "moment";
import { Joove } from "./Joove";
import * as numeral from 'numeral';

export class ValueFormat {
  public live: boolean;
  public decimals: number;
  private dateMode: boolean;
  private dateFormat: string;
  public prefix: string;
  public postfix: string;
  private signed: boolean;
  private showOnlyDecimalPart: boolean;
  private groups: boolean;
  private asPercentage: boolean;

  private numeralJsFormat: string;
  private momentJsFormat: string;
  private backEndFormatting: string;
  private excelFormatting: string;

  constructor(options?: {
    dateFormat?: string,
    prefix?: string,
    postfix?: string,
    decimals?: number,
    signed?: boolean,
    showOnlyDecimalPart?: boolean,
    groups?: boolean,
    live?: boolean,
    asPercentage?: boolean,
    backEndFormatting?: string,
    dateMode?: boolean,
    numeralJsFormat?: string,
    momentJsFormat?: string,
  }) {
    if (options == null) {
      this.decimals = 2;
      this.groups = true;
    }
    else {
      this.dateFormat = options.dateFormat;
      this.prefix = options.prefix;
      this.postfix = options.postfix;
      this.decimals = options.decimals;
      this.signed = options.signed;
      this.showOnlyDecimalPart = options.showOnlyDecimalPart;
      this.groups = options.groups;
      this.live = options.live;
      this.asPercentage = options.asPercentage;
      this.backEndFormatting = options.backEndFormatting;
      this.dateMode = options.dateMode;
      this.numeralJsFormat = options.numeralJsFormat;
      this.momentJsFormat = options.momentJsFormat;
    }

    this.dateMode = Joove.Common.stringIsNullOrEmpty(this.dateFormat) == false;

    if (this.dateMode === true) {
     this.setMomentJsFormat();
    }
    else {
     this.setNumeralJsFormat();
    }
  }

  // this is a moment.js compatible format
  // https://momentjs.com/docs/#/parsing/string-format/
  private setMomentJsFormat() {
    this.momentJsFormat = this.dateFormat; // 1-to-1 for the moment
  }

  // This is a numeral.js compatible format
  // http://numeraljs.com/#format
  private setNumeralJsFormat() {
    Joove.Common.setNumberLocalizationSettings();

    var format = "";

    if (this.asPercentage === true) {
      format = "0";

      if (this.decimals > 0) {
        format += "." + new Array(this.decimals + 1).join("0");
      }

      format += "%";

    }
    else {
      if (this.showOnlyDecimalPart === true) {
        format = ".";
      }
      else if (this.groups === true) {
        format += "0,0";
      }
      else {
        format = "0";
      }

      if (this.signed === true) {
        format = "+" + format;
      }

      if (this.decimals == null) {
        format += '.[000000000000000000000000000000]'; // let decimal digits intact, without trimming or padding them with zeroes, hopefully will not ever exceed 30 decimal digits
      } else if (this.decimals > 0) {
        if (this.showOnlyDecimalPart !== true) {
          format += ".";
        }

        format += new Array(this.decimals + 1).join("0");
      } else if (this.showOnlyDecimalPart === true) {
        format = ".0";
      }
    }
    this.numeralJsFormat = format;
  }

  // https://github.com/RobinHerbots/Inputmask
  public getJqueryInputMaskOptions(): any {
    return {
      alias: this.decimals == 0 || this.decimals == null ? "integer" : "decimal",
      autoGroup: this.groups == true,
      digits: this.decimals,
      radixPoint: window._context.decimalSeparator,
      groupSeparator: window._context.groupSeparator,
      suffix: this.postfix,
      prefix: this.prefix,
      allowMinus: true,
      rightAlign: false,
      unmaskAsNumber: true,
    };
  }

  private formatNumber(actualNumber): string {
    
    if (actualNumber == null) return "";

    var numeralInstance = numeral(actualNumber) as any;

    if (numeralInstance.value() == null) return "";

    //var roundingFunc = numeralInstance.value() > 0 ? Math.floor : Math.ceil;
    var roundingFunc = Math.round;

    var formattedNumber = numeralInstance["format"](this.numeralJsFormat, roundingFunc);

    if (Joove.Common.stringIsNullOrEmpty(this.prefix) === false) {
        formattedNumber = this.prefix + formattedNumber;
    }

    if (Joove.Common.stringIsNullOrEmpty(this.postfix) === false) {
        formattedNumber += this.postfix;
    }

    return formattedNumber;
  }

  private unformatNumber(formattedNumber): number {
    if (formattedNumber == null) return null;

    if (formattedNumber.trim && formattedNumber.trim() == "") return null;

    if (formattedNumber.toString && formattedNumber.toString() == "NaN") return null;

    if (typeof formattedNumber === "number") return formattedNumber;

    // remove group separators
    var groupSep = window._context.groupSeparator;
    formattedNumber = formattedNumber.replace(new RegExp("\\" + groupSep, 'g'), '');

    // replace decimal separator with dots
    var decSep = window._context.decimalSeparator;
    formattedNumber = formattedNumber.replace(new RegExp("\\" + decSep, 'g'), '.');

    // remove everything except from numbers, minus sign and dots
    formattedNumber = formattedNumber.replace(/[^\d.-]/g, '');

    var actualNumber = parseFloat(formattedNumber);

    return isNaN(actualNumber) === true
      ? null
      : actualNumber;
  }

  private formatDate(actualDate): string {
    return moment(actualDate).format(this.momentJsFormat);
  }

  private unformatDate(formattedDate): Date {
    return formattedDate;
    //return moment(formattedDate, this.momentJsFormat).toDate();            
  }

  public format(actualValue, forceDateMode?: boolean): string {
    return this.dateMode === true || forceDateMode === true
      ? this.formatDate(actualValue)
      : this.formatNumber(actualValue);
  }

  public unformat(formattedValue): any {
    return this.dateMode === true
      ? this.unformatDate(formattedValue)
      : this.unformatNumber(formattedValue);
  }

  clone() {
    var valueFormat = new ValueFormat();

    return valueFormat;
  }
}
