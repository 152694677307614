
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SignalRService } from 'src/app/@framework/signalr/signalr.service';
import { environment } from 'src/environments/environment';
import * as Domain from 'src/app/@core/Domain';
import { System } from '@framework/core/clms.fw.dotnet';

@Injectable({
  providedIn: 'root'
})
export class EventsService extends SignalRService {
  
    SignIn$ = new Subject<any>();
SignOut$ = new Subject<any>();
ApplicationStart$ = new Subject<any>();
ApplicationEnd$ = new Subject<any>();
ApplicationError$ = new Subject<any>();
SessionStart$ = new Subject<any>();
OnInstanceSave$ = new Subject<any>();
FileDownload$ = new Subject<any>();
ExternalUserCreating$ = new Subject<any>();
UserAuthenticating$ = new Subject<any>();


  constructor() {
    super();
    this.buildConnection(environment.hubUrl);
    this.connect();
    this.registerOnServerEvents();
  }

  private registerOnServerEvents(): void {
    super.registerDefaultServerEvents();

    
                this.connection.on('SignIn', (eventId: string, timestamp: number, data: any) => {
                    this.SignIn$.next({ UserName: data?.UserName,Time: data?.Time });
                }); 

                this.connection.on('SignOut', (eventId: string, timestamp: number, data: any) => {
                    this.SignOut$.next({ UserName: data?.UserName,Time: data?.Time });
                }); 

                this.connection.on('ApplicationStart', (eventId: string, timestamp: number, data: any) => {
                    this.ApplicationStart$.next({  });
                }); 

                this.connection.on('ApplicationEnd', (eventId: string, timestamp: number, data: any) => {
                    this.ApplicationEnd$.next({  });
                }); 

                this.connection.on('ApplicationError', (eventId: string, timestamp: number, data: any) => {
                    this.ApplicationError$.next({ exception: data?.exception });
                }); 

                this.connection.on('SessionStart', (eventId: string, timestamp: number, data: any) => {
                    this.SessionStart$.next({  });
                }); 

                this.connection.on('OnInstanceSave', (eventId: string, timestamp: number, data: any) => {
                    this.OnInstanceSave$.next({ Instance: data?.Instance });
                }); 

                this.connection.on('FileDownload', (eventId: string, timestamp: number, data: any) => {
                    this.FileDownload$.next({ path: data?.path,username: data?.username });
                }); 

                this.connection.on('ExternalUserCreating', (eventId: string, timestamp: number, data: any) => {
                    this.ExternalUserCreating$.next({ user: data?.user });
                }); 

                this.connection.on('UserAuthenticating', (eventId: string, timestamp: number, data: any) => {
                    this.UserAuthenticating$.next({ username: data?.username });
                }); 

    
  }
}

