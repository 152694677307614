
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS, HttpBackend } from '@angular/common/http';
import { } from 'src/app/@framework/core/__global';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ClientCommandsService } from '@services/ClientCommands.service';
import { RouterHistoryService } from './@core/services/RouterHistory.service';
import { HttpResponseInterceptor } from './@framework/http/http-response.interceptor';
import { AuthInterceptor } from './@core/Helpers/Interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './@core/core.module';
import { zAppDevMenuControlModule } from './@framework/components/MenuControl/menucontrol.module';
import { zAppDevButtonModule } from './@framework/components/Button/button.module';
import { ZAppDevThemeModule } from './@framework/theme/theme.module';
import { IThemeSevice } from './@framework/theme/interfaces/theme.interface';
import {LightThemeService} from './@theming/services/LightTheme.services';
import { ThemeService } from './@theming/theme.service';
import { ListControlResourcesService } from '@framework/components/DataList/helpers/resources.service';
import { environment } from '../environments/environment';
import {ConfirmationService} from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import { first } from 'rxjs/operators';
import { PagesService } from './pages/pages.services';
import { PermissionService } from './@framework/security/services/permission.service';
import { Joove } from './@framework/core/Joove';
import { IPagesService } from './pages/pages.interface';
import { RequestInterceptor } from '@core/Helpers/RequestInterceptor';
import { ContextService } from '@core/services/Context.service';
import { FormsModule } from '@angular/forms';
import { zAppDevFormModalModule } from '@framework/components/FormModal/form-modal.module';
import { GoogleMapsService } from './@core/services/GoogleMaps.service';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
export function appInitializerFactory(
  pagesService: PagesService, 
  permissionService: PermissionService, 
  contextService: ContextService, 
  translateService: TranslateService, 
  googleMapsService: GoogleMapsService,
  themeService: ThemeService,
  injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    pagesService.refreshContext('MasterPage', 'Render').pipe(first()).subscribe((context: Joove.IContext) => {
      permissionService.updatePermissions(context.currentUserPermissions);
      contextService.update(context);
      googleMapsService.loadGoogleMapsScript(context.appSettings.googleMapsAPIKey);
      themeService.setThemes(['default']);
      themeService.setMainTheme(context.theme);
      translateService.use(context.currentLanguage.languageIetTag).pipe(first()).subscribe(() => {
        resolve(true);
      });
    });

  });
}

const DATA_SERVICES = [
  { provide: IThemeSevice, useClass: LightThemeService },
  ContextService,
  GoogleMapsService,
  ClientCommandsService,
  RouterHistoryService,
  ListControlResourcesService,
  ConfirmationService,
  { provide: IPagesService, useClass: PagesService },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi: true },
  {
    provide: APP_INITIALIZER,
    useFactory: appInitializerFactory,
    deps: [PagesService, PermissionService, ContextService, TranslateService, GoogleMapsService, ThemeService, Injector],
    multi: true
  },
];

export function createTranslateLoader(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: `${environment.i18nUrl}/assets/i18n/app/`, suffix: '.json' },
    { prefix: `${environment.i18nUrl}/assets/i18n/domain/`, suffix: '.json' },
    { prefix: `${environment.i18nUrl}/assets/i18n/primeng/`, suffix: '.json' },
  ]);
}

const reducers = {};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule.forRoot(),
    zAppDevMenuControlModule.forRoot(),
    zAppDevButtonModule.forRoot(),
    AppRoutingModule,
    zAppDevFormModalModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en-us',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpBackend]
      },
    }),
    ZAppDevThemeModule.forRoot(),
    ConfirmPopupModule,
    ConfirmDialogModule,
  ],
  providers: [...DATA_SERVICES],
  bootstrap: [AppComponent],
  exports: [ ]
})

export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory (http: HttpClient) {
  return new TranslateHttpLoader(http);
}
